import {SmartComponent} from "smart-component-js";
import Swiper from 'swiper/bundle';


class SchoolPageComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        super(element, parentComponent, params);
        this.element = element;

        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;

        this.init();
    }


    initCarousel() {
        if ($(".black-quote .owl-carousel").length > 0) {
            $('.black-quote .owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: $('.black-quote .owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 3
                    },
                }
            })
        }
        if ($(".black .owl-carousel").length > 0) {
            $('.black .owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: $('.black .owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if ($(".four-white .owl-carousel").length > 0) {
            $('.four-white .owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: $('.four-white .owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if ($(".minimal .owl-carousel").length > 0) {
            $('.minimal .owl-carousel').owlCarousel({
                items: 1,
                rtl: this.isRtl,
                loop: $('.minimal .owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 3
                    },
                    1440: {
                        items: 4
                    },
                }
            })
        }
        if ($(".grey .owl-carousel").length > 0) {
            $('.grey .owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: $('.grey .owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if ($(".white .owl-carousel").length > 0) {
            $('.white .owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: $('.white .owl-carousel .card__image img').length > 3,
                margin: 25,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if ($(".dark-grey .owl-carousel").length > 0) {
            $('.dark-grey .owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: $('.dark-grey .owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                nav: true,
                dots: false,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-white.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-white.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }

        if ($(".white-cta .owl-carousel").length > 0) {
            $('.white-cta .owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: $('.white-cta .owl-carousel .card__image img').length > 3,
                margin: 25,
                stagePadding: 1,
                dots: false,
                nav: true,
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }
        if ($(".grey-cta .owl-carousel").length > 0) {
            $('.grey-cta .owl-carousel').owlCarousel({
                rtl: this.isRtl,
                loop: $('.grey-cta .owl-carousel .card__image img').length > 3,
                margin: 25,
                dots: false,
                nav: true,
                // navText: [$('.am-prev'), $('.am-next')],
                navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                    '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1280: {
                        items: 2
                    },
                    1440: {
                        items: 3
                    }
                }
            })
        }

    }

    init() {
        this.initCarousel();
    }
}

export default SchoolPageComponent;