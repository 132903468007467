import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class CampusInfoComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CampusInfoComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        this.$el.find('.cci_tab').hide();
        this.$el.find('.cci_tabs').show();
        this.$el.find('.cci_title').click((e) => {
            this.$el.find('.cci_title').removeClass('active');
            $(e.currentTarget).addClass('active');

            let tab = $(e.currentTarget).data('tab');
            this.$el.find('.cci_tab').hide();
            this.$el.find('.cci_tab[data-tab="' + tab + '"]').show();
        })

        this.$el.find('[plain-text-to-accordion]').each((i, el) => {
            let $container = $(el);
            let lines = $container.find('p').remove();
            let title = false;
            let html = "";

            for (let j = 0; j < lines.length; j++) {
                if ($(lines[j]).html().trim().startsWith('<strong>')) {
                    if (!title) {
                        html += '</div><div class="cci_accordion_item">';
                    }
                    html += '<div class="title">' + $(lines[j]).html() + '</div>';

                    title = true;
                } else {
                    html += '<div class="description">' + $(lines[j]).html() + '</div>';

                    title = false;
                }
            }

            $container.append(html);
        })

        this.$el.find('.cci_accordion_item .title').click((e) => {
            $(e.currentTarget).parents('.cci_accordion_item').toggleClass('active');
        });
        this.$el.find('.cci_title:eq(0)').click();
    }
}

export default CampusInfoComponent;