import {SmartComponent} from "smart-component-js";

class CourseDetailCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CourseDetailCtrl";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$campusTriggerWrapper = this.$el.find('#campus-trigger-wrapper');
        this.$campusWrapper = this.$el.find('#campus-wrapper');

        this.init();
    }

    init() {
        $('.flyer-cta').attr('data-mobile-hide', '');
        this.$el.find('.campus-wrapper').each((i, el) => {
            let $campus = $(el);
            this.$campusTriggerWrapper.append(`<a class="campus-trigger" data-code="${$campus.data('code')}">${$campus.data('label')}</a>`);
        });
        this.$el.find('#campus-trigger-wrapper .campus-trigger').click((e) => {
            this.$el.find('#campus-trigger-wrapper .campus-trigger').removeClass('active');
            $(e.currentTarget).addClass('active');

            let campusCode = $(e.currentTarget).data('code');
            this.$campusWrapper.find('.campus-wrapper').hide();
            this.$campusWrapper.find('.campus-wrapper[data-code="' + campusCode + '"]').show();

            this.$el.find('#ects-wrapper').html('').hide();
            let ects = this.$campusWrapper.find('.campus-wrapper[data-code="' + campusCode + '"] .campus-ects');
            if (ects.length > 0) {
                this.$el.find('#ects-wrapper').html(ects.html()).show();
            }
            this.$el.find('#validation-wrapper').html('').hide();
            let validations = this.$campusWrapper.find('.campus-wrapper[data-code="' + campusCode + '"] .campus-validations');
            if (validations.length > 0) {
                this.$el.find('#validation-wrapper').html(validations.html()).show();
            }
            this.$el.find('#progspec-wrapper').html('').hide();
            let progSpecs = this.$campusWrapper.find('.campus-wrapper[data-code="' + campusCode + '"] .campus-prog-spec');
            if (progSpecs.length > 0) {
                this.$el.find('#progspec-wrapper').html(progSpecs.html()).show();
            }
        });
        this.$campusTriggerWrapper.find('.campus-trigger:eq(0)').click();
    }
}

export default CourseDetailCtrl;