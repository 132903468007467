import {SmartComponent} from "smart-component-js";

class VideoSectionComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "VideoSectionComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        //Al click su play nascondo il bottone e faccio partire il video
        this.$el.find('.play-btn').click((e) => {
            $(e.target).parents('.video-element').addClass('playing');
            $(e.target).siblings('.video').attr('controls', '').attr('controlsList', 'nodownload')[0].play();

        });

        //Al click sul titolo del video nascondo il video corrente e mostro il nuovo, ed aggiorno lo stato dei titoli
        this.$el.find('.video-title-wrapper [data-video-id]').click((e) => {
            this.$el.find('.video-title-wrapper .active').removeClass('active');
            let id = $(e.target).addClass('active').data('videoId');
            this.$el.find('.video-wrapper .active').removeClass('active');
            this.$el.find('.video-wrapper [data-video-id="' + id + '"]').addClass('active');

            //Se ci sono video in riproduzione li stoppo
            if (this.$el.find('.playing').length > 0) {
                this.$el.find('.playing video').removeAttr('controls').removeAttr('controlsList');
                this.$el.find('.playing video')[0].pause();
                this.$el.find('.playing video')[0].currentTime = 0;
                this.$el.find('.playing video')[0].load();
                this.$el.find('.playing').removeClass('playing');
            }
        });

        //Quando il video finisce mostro ancora il play
        this.$el.find('video').on('ended', (e) => {
            $(e.currentTarget).removeAttr('controls').removeAttr('controlsList');
            this.$el.find('.playing').removeClass('playing');
        });

        //All'avvio clicco il primo elemento.
        this.$el.find('.video-title-wrapper [data-video-id]:eq(0)').click();
    }
}

export default VideoSectionComponent;